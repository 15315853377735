.columnWrapper {
  display: flex;
  gap: 3rem;
  justify-content: space-between;
}

@media screen and (max-width: 1150px) {
  .columnWrapper {
    flex-direction: column;
  }
}
