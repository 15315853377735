.container {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 20rem;
  border-radius: 4px;
}

/* .searchIcon {
  position: absolute;
  right: -0.5rem;
  z-index: 1;
  margin-right: 0.5rem;
  height: 1.5rem;
  color: #5a6268;
} */
