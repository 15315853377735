body,
html,
#root,
.App {
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  padding: 1rem 2rem;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mt {
  top: 0;
  position: relative;
}

.notAuthenticatedWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
