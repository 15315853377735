.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding-top: 5rem;
  margin: 3.5rem 0;
  overflow: hidden;
}

.title {
  color: #0353a4cc;
  font-size: 2.1rem;
  font-weight: 800;
  margin: 1.5rem 0;
  font-family: lato;
}
