.rightSideWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0.3rem 0.3rem 0.4rem rgb(0 0 0 / 5%);
  margin: 0.5rem 0;
  padding: 0.5rem;
}

.row * {
  font-size: 0.9rem;
}

.switchButton {
  margin-left: 1.5rem;
  /* background: #e26d5c; */
  /* background: #fb8500; */
  background: #ff8500;
  outline: none;
  border: none;
  color: white;
  padding: 0.35rem 0.7rem;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 700;
}

.switchButton:hover {
  background: #fa9500;
}
